import React, { useState } from 'react';
import './App.css';
import ProjectsPage from './pages/ProjectsPage';
import ExperiencePage from './pages/ExperiencePage';
import HomePage from './pages/HomePage';
import Navigation from './components/Navigation';
import * as dataEn from './data';
import * as dataNl from './datanl';

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [language, setLanguage] = useState('en');

  // Get the correct data based on language
  const currentData = language === 'en' ? dataEn : dataNl;

  // Translations for page titles
  const pageTitles = {
    en: {
      projects: 'My Projects',
      experience: 'Personal Experience'
    },
    nl: {
      projects: 'Mijn Projecten',
      experience: 'Persoonlijke Ervaring'
    }
  };

  const renderPage = () => {
    const titles = pageTitles[language];
    switch (currentPage) {
      case 'projects':
        return <ProjectsPage 
          projects={currentData.projects} 
          personalia={currentData.personalia}
          pageTitle={titles.projects}
          language={language}
        />;
      case 'experience':
        return <ExperiencePage 
          experiences={currentData.experiences} 
          personalia={currentData.personalia}
          pageTitle={titles.experience}
          language={language}
        />;
      case 'home':
      default:
        return <HomePage 
          home={currentData.home}
          personalia={currentData.personalia}
          language={language}
        />;
    }
  };

  return (
    <div className="app">
      <Navigation 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        language={language}
        setLanguage={setLanguage}
        data={currentData}
      />
      <main className="main-content">
        {renderPage()}
      </main>
    </div>
  );
}

export default App;